<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__header">
        <h3>Notifications</h3>
      </div>
      <div class="ps-section__content">
        <div class="table-responsive">
          <table class="table ps-table ps-table--invoices">
            <thead class="thead-light">
            <tr>
              <th>Id</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="5" class="text-center m-4 text-danger">No notifications available</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import UserSideNav from '@/components/mechantnavs/UserSideNav.vue';
import global from '@/mixins/global';
import axios from "axios";
import {mapState} from "vuex";

export default {
  mixins:[global],
  data() {
    return {
      loading: false,
      orders: [],
    };
  },
  mounted() {
    this.getOrders();
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
  },
  methods: {
    getOrders() {
      this.loading = true;
      axios.get( "orders").then((response) => {
        this.orders = response.data.data;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
      });
    },
  }
}
</script>

<style>
</style>